<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" />
    <div class="tab">
      <!-- <div @click="change(1)" :class="active == 1 ? 'bor_bot' : 'bor'">
        充值记录
      </div>
      <div plain @click="change(2)" :class="active == 2 ? 'bor_bot' : 'bor'">
        消费记录
      </div>

      <div plain @click="change(3)" :class="active == 3 ? 'bor_bot' : 'bor'">
        调整记录
      </div> -->
      <div class="rl">
        <!-- <div class="time" @click="calendar">{{ start_time }}-{{ end_time }}</div> -->
        <input class="time" v-model="mobile" placeholder="请输入会员手机号搜索" />
        <div class="ss" @click="search">搜索</div>
      </div>
    </div>
    <!-- <van-calendar v-model="show" type="range" @confirm="onConfirm" :minDate="min_time" /> -->
    <vxe-table
      v-if="active == 2"
      border
      :data="tableData"
      :row-config="{ isHover: true }"
      height="75vh"
      max-height="75vh"
      class="record-table"
    >
      <vxe-column field="order_num" title="订单单号"></vxe-column>
      <vxe-column field="consume_amount" title="消费金额" width="80"></vxe-column>
      <vxe-column field="pay_type" title="支付方式" width="80"></vxe-column>
      <vxe-column field="create_time" title="收银时间"></vxe-column>
      <vxe-column field="cashier_name" title="收银员"></vxe-column>
    </vxe-table>

    <vxe-table
      v-else-if="active == 1"
      border
      :row-config="{ isHover: true }"
      :data="tableData"
      height="60%"
      align="center"
    >
      <vxe-column field="order_number" title="充值单号"></vxe-column>
      <vxe-column field="nickname" title="会员姓名"></vxe-column>
      <vxe-column field="recharge_amount" title="充值金额"></vxe-column>
      <vxe-column field="give_amount" title="赠送金额"></vxe-column>
      <vxe-column field="pay_way" title="支付方式"></vxe-column>
      <vxe-column field="create_time" title="充值时间"></vxe-column>
      <vxe-column field="remark" title="备注"></vxe-column>
      <vxe-column field="shop_name" title="门店名称"></vxe-column>
      <!-- 0-线上 1-线下 -->
      <vxe-column field="type" title="充值渠道">
        <template #default="{row}">
          <span>{{ row.type == 1 ? "线下":"线上" }}</span>
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-table v-else border :row-config="{ isHover: true }" :data="tableData">
      <vxe-column field="type" title="调整类型"></vxe-column>
      <vxe-column field="balance" title="调整后余额"></vxe-column>
      <vxe-column field="mark" title="备注"></vxe-column>
      <vxe-column field="create_time" title="调整时间"></vxe-column>
      <vxe-column field="cashier_name" title="收银员"></vxe-column>
    </vxe-table>

    <van-col span="12">
      <div style="width: fit-content; margin: 3rem 0 3rem 8rem">
        <van-pagination
          v-model="page"
          :total-items="total"
          :items-per-page="limit"
          @change="pageChange"
          force-ellipses
        />
      </div>
    </van-col>
  </div>
</template>
<script>
import titlebar from "@/views/component/titlebar";
import gettimeleft from '@/utils/getTimeLeft.js'
export default {
  components: {
    titlebar: titlebar,
  },
  name: "record",
  data() {
    return {
      name: "",
      active: 1,
      allAlign: null,
      tableData: [],
      start_time: '2020-1-1',
      end_time: '12',
      min_time:  new Date(2020, 0, 1),
      show:false,
      page: 1,
      limit: 8,
      total: 0,
      mobile: ''
    };
  },
  created() {
    this.name = this.$route.query.name || ""; //拿到传过来的标题
    // this.maxdate = new Date();
    // var data = new Date()
    // this.end_time = gettimeleft(data)
    this.getlist();
    // this.start_time = 
  },
  methods: {
    // 页码改变
    pageChange(e) {
      this.page = e;
      console.log("记录列表，当前页码：", this.page);
      if (this.active = 1) {
        this.getlist();
      }
    },
    change(test) {
      this.list = [];
      this.active = test;
      if (this.active = 1) {
        this.getlist();
      }
    },
    getlist() {
      this.$api.getMemberChargeOrderList({
        page: this.page,
        pageSize: this.limit,
        mobile: this.mobile
      }).then((res) => {
        this.tableData = res.data.list;
        // this.tableData.forEach((item) => {
        //   item.pay_type =
        //     item.pay_type == 1
        //       ? "微信"
        //       : item.pay_type == 2
        //       ? "支付宝"
        //       : item.pay_type == 3
        //       ? "现金"
        //       : item.pay_type == 4
        //       ? "线上"
        //       : item.pay_type == 5
        //       ? "其他"
        //       : item.pay_type == 6
        //       ? "余额"
        //       : "";
        //   item.type = item.type == 2 ? "增加" : item.type == 3 ? "减少" : "";
        // });
      });
    },
    calendar() {
      this.show = true
    },
    onConfirm(e) {
      this.show = false
      var star = e[0].getTime()
      var end = e[1].getTime()
      this.start_time = gettimeleft(star)
      this.end_time = gettimeleft(end)
    },
    search() {
      this.getlist()
    }
  },
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  height: 100%;
  background: #fff;
}
th {
  font-size: 2.6rem;
  padding: 0px 5rem;
  line-height: 7rem;
  font-weight: 600;
}
.tab {
  display: flex;
  margin: 4rem;
  line-height: 0rem;
  // justify-content: space-evenly;
  padding-top: 3rem;

  .bor {
    font-weight: 800;
    font-size: 1.63rem;
    color: #666666;
    margin-bottom: 3rem;
  }
  .bor_bot {
    color: #333333;
    border-bottom: 1rem solid #1588f5;
    font-weight: 800;
    font-size: 1.63rem;
    margin-bottom: 3rem;
  }
  .rl {
    display: flex;
    width: 40%;
    .time {
      width: 100%;
      background: #f2f2f2;
      height: 6rem;
      margin-top: -2.7rem;
      border-radius: 1rem;
      line-height: 6rem;
      padding: 0 3rem;
      font-size: 2.4rem;
    }
    .ss {
      background: #1588f5;
      border-radius: 0.5rem;
      margin-top: -2.7rem;
      margin-left: 3rem;
      height: 6rem;
      width: 12rem;
      text-align: center;
      color: white;
      line-height: 6rem;
      font-weight: 500;
      font-size: 2.5rem;
    }
  }
}

.vxe-table {
  font-size: 2rem;
  margin: 0 4rem;
}
</style>